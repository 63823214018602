import React, { useState, useEffect, useContext, useRef } from "react";
import "./style.css";
import Menu from "../menu";
import { useSelector } from "react-redux";
import Message from "./message";
import { ChatContext } from "../../students";
import { getContacts } from "../../../api/chat";
import { Loader } from "../../common/styled-components/loader";
import { useTranslation } from "react-i18next";

const Chat = () => {
  const {
    users,
    chats,
    chatSelected,
    setChatSelected,
    handleSendMessage,
    handleCreateChat,
    markChatRead,
    createNickReceived,
  } = useContext(ChatContext);
  const { user } = useSelector((state) => ({
    user: state.user,
  }));
  const { t, i18n } = useTranslation(["chat"]);
  const [message, setMessage] = useState("");
  const [contacts, setContacts] = useState([]);
  const [conversations, setConversations] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [tabActive, setTabActive] = useState("contacts");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [loading, setLoading] = useState(false);
  const divRef = useRef(null);
  const username = user.Username;

  useEffect(() => {
    if (chatSelected) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatSelected]);

  useEffect(() => {
    const nicks = users.map((user) => user.username);
    const data = chats.map((chat) => {
      chat.online = nicks.includes(createNickReceived(chat.users, username));
      return chat;
    });
    setConversations(data);
    setUsernames(nicks);
  }, [users]);

  useEffect(() => {
    if (contacts.length) {
      const nicks = users.map((user) => user.username);
      const data = chats.map((chat) => {
        chat.name = createNickReceived(chat.users, username);
        chat.online = nicks.includes(createNickReceived(chat.users, username));
        return chat;
      });
      setConversations(data);
    }
  }, [chats, contacts]);

  useEffect(() => {
    getContactsChat();
  }, []);

  const getContactsChat = async () => {
    setLoading(true);
    try {
      const res = await getContacts(page, pageSize);
      const data = res.data;
      setContacts(data.UserInfo);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (message) {
      handleSendMessage(message);
      setMessage("");
    }
  };

  const startConversation = (contact) => {
    handleCreateChat(contact.Username);
    setTabActive("messages");
  };

  const handleChatSelected = (item) => {
    setChatSelected(item);
    markChatRead(item);
  };

  const createChatName = (nickname) => {
    const contact = contacts.find((elem) => elem.Username === nickname);
    return contact ? contact.GivenName + " " + contact.Surname : "";
  };

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="wrapper-chat">
          <div className="wrapper-col-chat">
            <div className="col-chat__contacts">
              <div className="chat__headerTitle">
                {tabActive === "contacts" && (
                  <div className="chat__headerTitle--title"> {t("contacts")} </div>
                )}
                {tabActive === "messages" && (
                  <div className="chat__headerTitle--title"> {t("messages")} </div>
                )}
              </div>

              <div className="chat__center-content">
                {tabActive === "contacts" && (
                  <div className="chat__center-content__wrapper-mod-contacts">
                    {contacts.map((item, i) => (
                      <div
                        key={i}
                        className="chat__center-content__article"
                        onClick={() => startConversation(item)}
                      >
                        <div className="content__article__avatar">
                          <img
                            className="content__article__avatar--img"
                            src={require("../../../assets/images/sample-avatar.png")}
                          />
                        </div>
                        <div className="content__article__user">
                          <div className="content__article__user__name">
                            {item.GivenName} {item.Surname}
                          </div>

                          {usernames.includes(item.Username) ? (
                            <p className="content__article__user__status">
                              <span className="content__article__user__status--ON">
                                <i className="fas fa-circle"></i>
                              </span>
                              {t("online")}
                            </p>
                          ) : (
                            <p className="content__article__user__status">
                              <span className="content__article__user__status--OFF">
                                <i className="fas fa-circle"></i>
                              </span>
                              {t("disconnected")}
                            </p>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {tabActive === "messages" && (
                  <div className="chat__center-content__wrapper-mod-contacts">
                    {conversations.map((item, i) => (
                      <div
                        key={i}
                        className="chat__center-content__article"
                        onClick={() => handleChatSelected(item)}
                      >
                        <div className="content__article__avatar">
                          <img
                            className="content__article__avatar--img"
                            src={require("../../../assets/images/sample-avatar.png")}
                          />
                        </div>
                        <div className="content__article__user">
                          <div
                            className={
                              chatSelected && item._id === chatSelected._id
                                ? "content__article__user__name active"
                                : "content__article__user__name"
                            }
                          >
                            {createChatName(item.name)}
                          </div>
                          {item.online ? (
                            <p className="content__article__user__status">
                              <span className="content__article__user__status--ON">
                                <i className="fas fa-circle"></i>
                              </span>
                              {t("online")}
                            </p>
                          ) : (
                            <p className="content__article__user__status">
                              <span className="content__article__user__status--OFF">
                                <i className="fas fa-circle"></i>
                              </span>
                              {t("disconnected")}
                            </p>
                          )}
                        </div>
                        {!item.read[username] && (
                          <div className="content__article__user__pointer">
                            <i className="fas fa-circle"></i>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <ul className="col-chat__contacts__menu">
                <li
                  className={
                    tabActive === "contacts"
                      ? "contacts__menu--item active"
                      : "contacts__menu--item"
                  }
                >
                  <a
                    onClick={() => {
                      setTabActive("contacts");
                      setChatSelected(null);
                    }}
                  >
                    <p className="contacts__menu--item__icon">
                      <i className="fas fa-user"></i>
                    </p>
                    <p className="contacts__menu--item__txt">{t("contacts")}</p>
                  </a>
                </li>

                <li
                  className={
                    tabActive === "messages"
                      ? "contacts__menu--item active"
                      : "contacts__menu--item"
                  }
                >
                  <a onClick={() => setTabActive("messages")}>
                    <p className="contacts__menu--item__icon">
                      <i className="fas fa-comments"></i>
                    </p>
                    <p className="contacts__menu--item__txt">{t("messages")}</p>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-chat__messages">
              {chatSelected && (
                <div className="chat__headerTitle">
                  <div className="chat__center-content__article">
                    <div className="content__article__avatar">
                      <img
                        className="content__article__avatar--img"
                        src={require("../../../assets/images/sample-avatar.png")}
                      />
                    </div>
                    <div className="content__article__user">
                      <div className="content__article__user__name">
                        {createChatName(chatSelected.name)}
                      </div>
                      {chatSelected.online ? (
                        <p className="content__article__user__status">
                          <span className="content__article__user__status--ON">
                            <i className="fas fa-circle"></i>
                          </span>
                          {t("online")}
                        </p>
                      ) : (
                        <p className="content__article__user__status">
                          <span className="content__article__user__status--OFF">
                            <i className="fas fa-circle"></i>
                          </span>
                          {t("disconnected")}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {chatSelected && (
                <div className="chat__center-content">
                  <div className="chat__center-content__wrapper-mod-messages">
                    {chatSelected.messages.map((message, i) => (
                      <div key={i}>
                        <Message message={message} username={username} />
                      </div>
                    ))}
                    <div ref={divRef}></div>
                  </div>

                  <form
                    onSubmit={sendMessage}
                    className="col-chat__messages__form"
                  >
                    <input
                      type="text"
                      name="user"
                      placeholder={t("writeMessage")}
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      onKeyPress={(e) =>
                        e.key === "Enter" ? sendMessage(e) : null
                      }
                    />
                    <button className="form__button--send">
                      <i className="fas fa-paper-plane"></i>
                    </button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
        {loading && <Loader />}
      </div>
    </>
  );
};

export default Chat;
