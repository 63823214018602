export const isAuthenticated = () => {
  return localStorage.getItem("auth") !== null;
};

export const getUser = () => {
  return JSON.parse(localStorage.getItem("auth"));
};

export const getLanguage = () => {
  return localStorage.getItem("i18nextLng");
};

export const getSuject = () => {
  return JSON.parse(localStorage.getItem("suject"));
};

export const isMST = () => {
  return localStorage.getItem("mst") !== null;
};

export const hasRole = (user, roles) => {
  return roles.some(role => user.Roles.includes(role));
}

