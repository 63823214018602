import React from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import LiveChat from "react-livechat";
import { LICENSE_LIVE_CHAT } from "../../../config/environment";
import { isMST } from "../../../utils/utils";
import { BASE_URL_SITE_TEACHER, IS_APP_MANAGED_BY_REQUIRED } from "../../../config/environment";

const FooterLogin = () => {
  const { t, i18n } = useTranslation(["login"]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      {!isMST() && <LiveChat license={LICENSE_LIVE_CHAT} />}
      <footer className="main-footer">
        <div className="footer-languages-login">
          <ul className="footer-languages_languages">
            <li>
              <i className="fas fa-globe"></i>
            </li>
            <li>
              <a onClick={() => changeLanguage("es")}>
                {" "}
                Esp<span>añol</span>{" "}
              </a>
            </li>
            <li>
              <a onClick={() => changeLanguage("en")}>
                {" "}
                Eng<span>lish</span>{" "}
              </a>
            </li>
          </ul>
          <p>{t("service")} (787) 931-7105</p>
        </div>

        <div className="footer-copyright">
          <p>
            <a href={`${BASE_URL_SITE_TEACHER}/#/terms`} target="_blank">
              {" "}
              {t("security")}{" "}
            </a>
          </p>
          {IS_APP_MANAGED_BY_REQUIRED && (<p>
            <a
              href={`${BASE_URL_SITE_TEACHER}/#/billing-terms`}
              target="_blank"
            >
              {" "}
              {t("billing")}{" "}
            </a>
          </p>)}
          <p className="red-link">
            <a href={`${BASE_URL_SITE_TEACHER}/#/privacy`} target="_blank">
              {" "}
              {t("coppa")}{" "}
            </a>
          </p>
          <p> © {t("copyrigth")}</p>
        </div>
      </footer>
    </>
  );
};

export default FooterLogin;
