import React, { useState, useEffect } from "react";
import "./style.css";
import { getHomeworkAssignedByTeacher } from "../../../../api/lesson";
import { SkeletonStyledTask } from "../../../common/styled-components/skeleton";
import IconCongratsTrophy from "../../../../assets/images/icon-congratulations.png";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const MessageCongratsHomework = styled.div`
  color: #222;
  display: block;
  justify-content: center;
  text-align: center;
  margin: 3em auto;
  font-size: 13px;

  & img {
    display: block;
    margin: 2em auto 0.25em auto;
  }

  & h1 {
    font-size: 30px;
    line-height: 0;
    color: #f2af2b;
  }
`;

const Item = ({ item }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation(["home"]);

  return (
    <>
      <div className="content-item-homework">
        <div className="item-homework--homework">
          <div className="homework-description">
            <p className="txt-homework">{item.Title}</p>
            <p className="date-homework">
              {t("dueDate")}: {item.DueDate}
            </p>
          </div>
        </div>

        <div className="item-homework--button">
          <button
            type="submit"
            className="button-homework"
            onClick={() =>
              history.push(`/students/homework/${item.TeacherPlanQuizId}`)
            }
          >
            {" "}
            {t("viewHomework")}{" "}
          </button>
        </div>
      </div>
    </>
  );
};

const Homework = () => {
  const { t, i18n } = useTranslation(["home"]);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [homeworks, setHomeworks] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res = await getHomeworkAssignedByTeacher(page, pageSize);
      const data = res.data.HomeworkModelList ? res.data.HomeworkModelList : [];
      setHomeworks(data);
      setLoading(false);
    })();
  }, []);

  let component;
  if (!homeworks.length) {
    component = (
      <MessageCongratsHomework>
        <img src={IconCongratsTrophy} alt="" />{" "}
        <h1>{t("congratulations")}</h1> {t("emptyHomework")}
      </MessageCongratsHomework>
    );
  } else {
    component = (
      <>
        {homeworks.map((item, i) => (
          <Item key={i} item={item} />
        ))}
        {homeworks.length >= 5 && (
          <button
            onClick={() => history.push("/students/task?q=homework")}
            className="button-see-more"
          >
            {t("showMoreHomework")}
          </button>
        )}
      </>
    );
  }

  return (
    <>
      <div className="wrapper-home-task">
        <h2>{t("homework")}</h2>
        {loading ? (
          <SkeletonStyledTask>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
          </SkeletonStyledTask>
        ) : (
          component
        )}
      </div>
    </>
  );
};

export default Homework;
