import React, { useState, useEffect } from "react";
import { getCompletedAssignments } from "../../../../api/lesson";
import { SkeletonStyled } from "../../../common/styled-components/skeleton";
import styled from "styled-components";
import Pagination from "@material-ui/lab/Pagination";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getSubjectsAndCoursesThuk } from "../../../../redux/actions/subjects";
import { currentGrade } from "../../../../redux/actions/grade";
import { getLanguage } from "../../../../utils/utils";
import { getGradesOrdinals } from "../../../../utils/lesson";
import { getLessonsSuccess } from "../../../../redux/actions/lessons";
import { getNameSubject } from "../../../../utils/subject";
import NoBookIcon from "../../../../assets/images/icon-no-book.jpg";
import SeeDetailDialog from "./see-detail-dialog";

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;

  & img {
    display: block;
    margin: 2em auto 0.25em auto;
    font-size: 45px;
    opacity: .5;
  }

  & i {
    display: block;
    margin: 2em auto 0.25em auto;
    font-size: 45px;
    opacity: .5;
  }
`;

const SelectGrade = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #E9E9E9",
    color: state.isSelected ? "#FFF" : "#6F6F6F",
    padding: 10,
    fontSize: 12,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const CompletedAssignments = () => {
  const { t, i18n } = useTranslation(["task"]);
  const history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const { grade, subjects } = useSelector((state) => ({
    grade: state.grade,
    subjects: state.subjects,
  }));
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(0);
  const [homeworks, setHomeworks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gradeSelected, setGradeSelected] = useState(null);
  const [grades, setGrades] = useState([]);
  const [matterSelected, setMatterSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [quizResultId, setQuizResultId] = useState("");

  useEffect(() => {
    dispatch(getSubjectsAndCoursesThuk());
  }, []);

  useEffect(() => {
    const grades = Array.from(
      new Set(subjects.map((subject) => subject.Level))
    );
    const lists = grades.map((grade) => ({
      value: grade,
      label: getGradesOrdinals(grade, getLanguage()),
    }));
    setGrades(lists);
    setGradeSelected(
      grade
        ? { value: grade, label: getGradesOrdinals(grade, getLanguage()) }
        : {
            value: grades[0],
            label: getGradesOrdinals(grades[0], getLanguage()),
          }
    );
    if (!grade) {
      dispatch(currentGrade(grades.length ? grades[0] : null));
    }
  }, [subjects]);

  const getCompletedAssignmentsPaginate = async (grade, subject, pag, size) => {
    setLoading(true);
    try {
      const res = await getCompletedAssignments(grade, subject, pag, size);
      const data = res.data.HomeworkModelList ? res.data.HomeworkModelList : [];
      setHomeworks(data);
      setLoading(false);
      setCount(res.data.TotalPages);
    } catch {
      setLoading(false);
    }
  };

  const handleChangePaginate = (event, value) => {
    setPage(value);
    setHomeworks([]);
    getCompletedAssignmentsPaginate(
      grade,
      matterSelected.value,
      value,
      pageSize
    );
  };

  const handleChangeGrade = (selected) => {
    setGradeSelected(selected);
    dispatch(currentGrade(selected.value));
    dispatch(getLessonsSuccess([]));
    setHomeworks([]);
    setMatterSelected(null);
  };

  const handleChangeSubject = (selected) => {
    setHomeworks([]);
    setMatterSelected(selected);
    getCompletedAssignmentsPaginate(grade, selected.value, page, pageSize);
  };

  const handleClickOpen = (item) => {
    setOpen(true);
    setQuizResultId(item.UserQuizResultId);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="wrapper-col space-between">
        <div className="three-col">
          <span className="txt-select">{t("selectMatterAndLesson")}</span>
        </div>
        <div className="three-col">
          <div className="selectTrelloGrade">
            <Select
              styles={SelectGrade}
              value={gradeSelected}
              onChange={handleChangeGrade}
              options={grades}
              placeholder="Select Grade"
            />
          </div>
        </div>
        <div className="three-col">
          <div className="selectTrelloGrade">
            <Select
              styles={SelectGrade}
              value={matterSelected}
              onChange={handleChangeSubject}
              options={subjects
                .filter((course) => course.Level === grade)
                .map((item) => ({
                  value: item.Subject,
                  label: getNameSubject(item.Subject, subjects),
                }))}
              placeholder={t("selectMatter")}
            />
          </div>
        </div>
      </div>
      <div className="wrapper-table-homework-task">
        <table className="content-table-homework">
          <thead>
            <tr>
              <th>{t("assignedBy")}</th>
              <th>{t("homework")}</th>
              <th>{t("submitDate")}</th>
              <th>{t("percentage")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {homeworks.map((item, i) => (
              <tr key={i}>
                <td data-column="Asignado por:">
                  <span className="table-homework--name-matter">
                    {" "}
                    {item.SentBy}{" "}
                  </span>
                </td>
                <td data-column="Asignación:">
                  <span className="table-homework--title">{item.Title}</span>
                </td>
                <td data-column="Fecha de Entrega:">
                  <span className="table-homework--date">
                    {" "}
                    {item.SubmittedDate}{" "}
                  </span>
                </td>
                <td data-column="">
                  <span className="table-homework--date"> {item.TotalPercent}% </span>
                </td>
                <td data-column="">
                  <button
                    type="submit"
                    className="button-table-homework"
                    onClick={() => handleClickOpen(item)}
                  >
                    {" "}
                    {t("seeDetail")}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {loading && (
          <SkeletonStyled>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
            <div className="area"></div>
          </SkeletonStyled>
        )}
        {matterSelected && !loading && !homeworks.length && (
          <EmptyListStyled>
            <i className="fas fa-list-ul"></i> {t("emptyAssignments")}
          </EmptyListStyled>
        )}
        {!matterSelected && (
          <EmptyListStyled>
            <img src={NoBookIcon} alt="" /> {t("selectMatter")}
          </EmptyListStyled>
        )}
        {count > 1 && (
          <Pagination
            count={count}
            page={page}
            onChange={handleChangePaginate}
          />
        )}
        {open && (
          <SeeDetailDialog
            open={open}
            onClose={handleClose}
            quizResultId={quizResultId}
          />
        )}
      </div>
    </>
  );
};

export default CompletedAssignments;
