import React, { useEffect, useState } from "react";
import "./style.css";
import Select from "react-select";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {
  getSubscriptionsActivePeriods,
  getReportAverageByPeriod
} from "../../../api/lesson";
import Subject from "./subject";
import { useTranslation } from "react-i18next";
import moment from 'moment';

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;

  & i {
    display: block;
    margin: 2em 0 0.25em 0;
    font-size: 45px;
    opacity: .5;
  }
`;

const SelectGrade = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px solid #E9E9E9",
    color: state.isSelected ? "#FFF" : "#6F6F6F",
    padding: 10,
    fontSize: 12,
  }),

  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const Progress = ({ loading, setLoading }) => {
  const { t } = useTranslation(["progress"]);
  const { grade, subjects } = useSelector((state) => ({
    grade: state.grade,
    subjects: state.subjects,
  }));
  const [periodSelected, setPeriodSelected] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [averages, setAverages] = useState([]);
  const [subjectSelected, setSubjectSelected] = useState(null);
  const [homeworks, setHomeworks] = useState([]);

  useEffect(() => {
    getSubscriptionsActivePeriodsUser();
  }, []);

  const getSubscriptionsActivePeriodsUser = async () => {
    setLoading(true);
    try {
      const res = await getSubscriptionsActivePeriods();
      const data = res.data;
      const options = data.map(item => {
        return {
          value: item.Id,
          label: item.PeriodNumber + ": " + moment(item.PeriodFrom, "M/DD/YYYY hh:mm:ss A").format("MM/DD/YYYY") + " - " + moment(item.PeriodTo, "M/DD/YYYY hh:mm:ss A").format("MM/DD/YYYY")
        };
      });
      setPeriods(options);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getAveragesSubjects = async (periodId) => {
    setLoading(true);
    try {
      const res = await getReportAverageByPeriod(periodId);
      const data = res.data;
      setAverages(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getDetailsSubjectAndPeriod = async (periodId, subjectCode, levelCode) => {
    setLoading(true);
    try {
      const res = await getReportAverageByPeriod(periodId, subjectCode, levelCode);
      const data = res.data[0].Details;
      setHomeworks(data);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const hadleSubjectSelected = (subject) => {
    setSubjectSelected(subject);
    getDetailsSubjectAndPeriod(periodSelected.value, subject.SubjectCode, subject.LevelCode);
  };

  const handleChangePeriods = (selected) => {
    setPeriodSelected(selected);
    getAveragesSubjects(selected.value);
    setHomeworks([]);
  };

  return (
    <>
      <div className="wrapper-lesson-tabs">
        <div className="container_two-col">
          <div className="wrapper-student-progress">
            <div className="general-subject">
              <div className="general-subject__title-search">
                <div className="title-search__col-left">
                  {t("period")}
                </div>
                <div className="title-search__col-right">
                  <div className="selectTrelloGrade">
                    <Select
                      styles={SelectGrade}
                      value={periodSelected}
                      onChange={handleChangePeriods}
                      options={periods}
                      placeholder={t("selectPeriod")}
                    />
                  </div>
                </div>
              </div>

              <p><small>{t("note")}</small></p>

              {averages.length > 0 && <p><small><strong>{t("selectMatterProgress")}</strong></small></p>}

              {averages.length > 0 &&
                averages.map((item, i) => (
                  <Subject
                    key={i}
                    subject={item}
                    hadleSubjectSelected={hadleSubjectSelected}
                    subjectSelected={subjectSelected}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className="container_two-col">
          <div className="wrapper-detail-average">
            <h3>{t("assignmentsTasks")}</h3>
            {subjectSelected && <h4>{subjectSelected.Name}</h4>}

            {homeworks.length > 0 && (
              <table className="detail-average__table">
                <thead>
                  <tr>
                    <th>{t("columDescription")}</th>
                    <th>{t("average")}</th>
                  </tr>
                </thead>

                <tbody>
                  {homeworks.map((item, i) => (
                    <tr key={i}>
                      <td>
                        {item.Title}                        
                      </td>
                      <td>{item.Average}%{" "}{item.AcademicGrade}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {!subjectSelected && (
              <EmptyListStyled>
                <i className="fas fa-book-open"></i>
                {t("selectMatter")}
              </EmptyListStyled>
            )}
            {subjectSelected && homeworks.length === 0 && (
              <EmptyListStyled>
                <i className="fas fa-book-open"></i>
                {t("emptyTask")}
              </EmptyListStyled>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Progress;
