import React, { useState, useEffect } from "react";
import "./style.css";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LessonTitle from "../lesson-title";
import { useSelector } from "react-redux";
import { getLessonByIdExamsTriesHomeSchooler, getLessonByIdExamsTriesSchool } from "../../../../../api/lesson";
import { getMaterialOffline } from "../../../../../api/lesson";
import moment from "moment";
import "moment-timezone";

const EmptyListStyled = styled.p`
  color: #ccc;
  display: block
  justify-content: center;
  text-align: center;
  margin: 3em auto;
`;

const ChallengeItem = ({ quiz, lessonId, setLoading }) => {
  const { t } = useTranslation(["quiz"]);
  const history = useHistory();
  const startDateTime = quiz.DateAvailable ? moment(quiz.DateAvailable, "MM/DD/YYYY HH:mm:ss")
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("MM/DD/YYYY hh:mm a") : "";
  const endDateTime = quiz.DateAvailable ? moment(quiz.DateAvailable, "MM/DD/YYYY HH:mm:ss").add(quiz.Duration, 'minutes')
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("MM/DD/YYYY hh:mm a") : "";

  const goTo = (path) => {
    if (endDateTime && quiz.IsExam === "1") {
      history.push(`${path}?h=${new Date(endDateTime).toISOString()}`);
    } else {
      history.push(path);
    }
  };

  const downloadQuiz = async (quizId, format) => {
    setLoading(true);
    try {
      const res = await getMaterialOffline(lessonId, quizId, format);
      const url = res.data;
      if (url) {
        window.open(url, "_blank");
      }
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return (
    <div className="container-quiz-lesson">
      <a className={!quiz.AvailableForUser ? "disabled" : ""}>
        <div className="col-left--quiz-lesson">
          <span onClick={!quiz.AvailableForUser ? undefined : () => goTo(`/students/lesson/quiz/${quiz.Id}`)}>{quiz.Title}</span>
          <p className="quiz-lesson__download">
            {t("downloadQuiz")}:
            <div className="quiz-lesson__download--btn">
              <div onClick={!quiz.AvailableForUser ? undefined : () => downloadQuiz(quiz.Id, "pdf")}><i className="quiz-lesson__icon-file-download fas fa-file-pdf quiz-lesson__icon-file-download__pdf"></i><span className="quiz-lesson__download-txt quiz-lesson__icon-file-download__pdf">PDF</span></div>
              <div onClick={!quiz.AvailableForUser ? undefined : () => downloadQuiz(quiz.Id, "doc")}><i className="quiz-lesson__icon-file-download fas fa-file-word quiz-lesson__icon-file-download__word"></i><span className="quiz-lesson__download-txt quiz-lesson__icon-file-download__word">Word</span></div>
            </div>
          </p>
        </div>

        <div className="col-left--quiz-lesson quiz-lesson__date" onClick={!quiz.AvailableForUser ? undefined : () => goTo(`/students/lesson/quiz/${quiz.Id}`)}>{`${startDateTime} - ${endDateTime}`}</div>

        <div className="col-center--quiz-lesson">
          {quiz.IsExam === "3" && <div className="col-with-fix--quiz-lesson"><i className="quiz-lesson__icon-waiting fas fa-clock"></i></div>}
          {quiz.IsExam === "2" && <div className="col-with-fix--quiz-lesson"><i className="quiz-lesson__icon-finish fas fa-check-circle"></i></div>}
          {quiz.IsExam === "1" && <><span>{quiz.TotalScore}%</span>
            <div className="progress">
              <div className="bar" style={{ width: `${quiz.TotalScore}%` }}></div>
            </div></>}
        </div>
      </a>
    </div>
  );
};

const Challenge = ({ lesson, kids, subject, lessonId, setLoading, loading }) => {
  const { t } = useTranslation(["detail-lesson"]);

  const { subscriptionType } = useSelector((state) => ({
    subscriptionType: state.subscriptionType
  }));

  const [exams, setExams] = useState([]);
  const [examsPrePost, setExamsPrePost] = useState([]);

  useEffect(() => {
    if (subscriptionType === "H") {
      getExamsByLessonHomeSchooler(lessonId);
    } else {
      getExamsByLessonSchool(lessonId);
    }
  }, []);

  const getExamsByLessonHomeSchooler = async (lessonId) => {
    setLoading(true);
    try {
      const res = await getLessonByIdExamsTriesHomeSchooler(lessonId);
      const data = res.data;
      let prepostList = [];
      let examsList = [];
      if (data.length > 0) {
        prepostList = data.filter(quiz => quiz.IsExam === "2" || quiz.IsExam === "3");
        examsList = data.filter(quiz => quiz.IsExam === "1");
      }
      setExams(examsList);
      setExamsPrePost(prepostList);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const getExamsByLessonSchool = async (lessonId) => {
    setLoading(true);
    try {
      const res = await getLessonByIdExamsTriesSchool(lessonId);
      const data = res.data;
      let prepostList = [];
      let examsList = [];
      if (data.length > 0) {
        prepostList = data.filter(quiz => quiz.IsExam === "2" || quiz.IsExam === "3");
        examsList = data.filter(quiz => quiz.IsExam === "1");
      }
      setExams(examsList);
      setExamsPrePost(prepostList);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  let className = "wrapper-iframe";
  if (kids) {
    className = "wrapper-iframe-kids";
  }

  return (
    <div className="wrapper-content">
      <div className="center-container">
        <div className={className}>
          {lesson && <LessonTitle subject={subject} lesson={lesson} />}
          <div className="wrapper-quiz-list">
            <h3>{t("selectChallege")}</h3>
            <div className="wrapper-lessons-progress">
              {examsPrePost.length > 0 &&
                examsPrePost.map((quiz, i) => <ChallengeItem key={i} quiz={quiz} lessonId={lessonId} setLoading={setLoading} />)}
            </div>
            <div className="wrapper-lessons-progress" style={{ marginTop: '2rem' }}>
              {exams.length > 0 &&
                exams.map((quiz, i) => <ChallengeItem key={i} quiz={quiz} lessonId={lessonId} setLoading={setLoading} />)}
            </div>
            {exams.length === 0 && (
              <EmptyListStyled>{t("emptyChallenge")}</EmptyListStyled>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenge;
