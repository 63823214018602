import { authApi } from "../../api/user";
import loading from "./loading";
import { DEFAULT_STATE, LOGIN_SUCCESS } from "../../constants/general";
import {
  GENIAL_WEB_LOGIN_PAGE
} from "../../config/environment";

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  };
};

export const logout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("subject");
  localStorage.removeItem("GLOBAL_STATE");
  window.location.href = GENIAL_WEB_LOGIN_PAGE;
  return {
    type: DEFAULT_STATE,
  };
};

export const login = (credentials, history) => {
  return async (dispatch, getState) => {
    try {
      dispatch(loading(true));
      const res = await authApi(credentials);
      const user = {
        InvitationCode: res.data.InvitationCode,
        Name: res.data.Name,
        Roles: res.data.Roles,
        Token: res.data.Token,
        Username: res.data.Username,
      };
      localStorage.setItem("auth", JSON.stringify(user));
      window.LC_API.hide_chat_window();
      dispatch(loginSuccess(user));
      history.push("/students/home");
    } catch {
    } finally {
      dispatch(loading(false));
    }
  };
};
