import React, { useEffect } from "react";
import "./style.css";
import Menu from "../menu";
import SubjectsHome from "./subjects";
import Homework from "./homework";
import Task from "./task";
import Lessons from "../lesson/lessons";
import { getSubjectsAndCoursesThuk } from "../../../redux/actions/subjects";
import { activity } from "../../../api/user";
import { useSelector, useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  const { subjects } = useSelector((state) => ({
    subjects: state.subjects,
  }));

  useEffect(() => {
    dispatch(getSubjectsAndCoursesThuk());
    sendActivity();
  }, []);

  const sendActivity = async () => {
    await activity();
  };

  return (
    <>
      <Menu />
      <div className="wrapper-content">
        <div className="center-container">
          <div className="container_two-col">
            <SubjectsHome subjects={subjects} />
            <div className="total-wrapper_lessons">
              <Lessons />
            </div>
          </div>
          <div className="container_two-col">
            <Task /><br />
            <Homework />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
